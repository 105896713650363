<template>
	<div>
		<!-- 第一步 填写姓名和手机号-->
		<div class="page" v-if="step == 1">
			<div class="banner">
				<img width="100%" src="../assets/bg.png" alt="">
			</div>

			<van-form class="form">
				<van-field v-model="form.name" name="姓名" label="姓名" placeholder="请输入姓名或昵称" />
				<van-field v-model="form.phone" type="tel" name="手机号" label="手机号" placeholder="请填写手机号" />
			</van-form>
			<div class="btn">
				<van-button style="width: 100%" round block type="info" @click="onSubmit1">下一步</van-button>
			</div>
		</div>

		<div class="page" v-if="step == 2">
			<div class="banner">
				<img width="100%" src="../assets/bg.png" alt="">
			</div>

			<div class="form" style="margin-bottom: 0">
				<div class="line">
					<div class="l"></div>
					<div class="title">请选择一组素材风格</div>
					<div class="l"></div>
				</div>
				<div class="sucai-list">
					<div class="sucai-row" v-for="(row, key) in tplList" :key="key" :class="{ 'active': form.tpl_id == row.id }"
						@click="selectTpl(row)">
						<div class="img" v-for="(img, key2) in row.show_imgs" :key="key2">
							<img :src="API + img" alt="">
						</div>
					</div>
				</div>
			</div>

			<div class="btn">
				<van-button  style="width: 100%" round block type="info" @click="onSubmit2">下一步</van-button>
			</div>
		</div>

		<div class="page" v-if="step == 3">
			<div class="banner">
				<img width="100%" src="../assets/bg.png" alt="">
			</div>
			<van-form class="form">
				<van-field v-model="form.keywords" rows="3" autosize label="关键词" type="textarea"
					placeholder="请填写关键词，AI将根据您的关键词生成图片" />

				<!-- 灯光 -->
				<van-field readonly clickable label="灯光" :value="dengguang.value" placeholder="请选择"
					@click="dengguang.showPicker = true" />
				<van-popup v-model="dengguang.showPicker" round position="bottom">
					<van-picker show-toolbar :columns="dengguang.columns" @cancel="dengguang.showPicker = false"
						@confirm="onConfirm1" />
				</van-popup>

				<!-- 色调 -->
				<van-field readonly clickable label="色调" :value="sediao.value" placeholder="请选择"
					@click="sediao.showPicker = true" />
				<van-popup v-model="sediao.showPicker" round position="bottom">
					<van-picker show-toolbar :columns="sediao.columns" @cancel="sediao.showPicker = false" @confirm="onConfirm2" />
				</van-popup>

				<!-- 精度 -->
				<van-field readonly clickable label="精度" :value="jingdu.value" placeholder="请选择"
					@click="jingdu.showPicker = true" />
				<van-popup v-model="jingdu.showPicker" round position="bottom">
					<van-picker show-toolbar :columns="jingdu.columns" @cancel="jingdu.showPicker = false" @confirm="onConfirm3" />
				</van-popup>

				<!-- 艺术风格 -->
				<van-field readonly clickable label="艺术风格" :value="yishufengge.value" placeholder="请选择"
					@click="yishufengge.showPicker = true" />
				<van-popup v-model="yishufengge.showPicker" round position="bottom">
					<van-picker show-toolbar :columns="yishufengge.columns" @cancel="yishufengge.showPicker = false"
						@confirm="onConfirm4" />
				</van-popup>

			</van-form>

			<div class="btn">
				<van-button  style="width: 100%" round block type="info" @click="onSubmit3">提交</van-button>
			</div>
		</div>

		<div class="page" v-if="step == 4">
			<div class="banner">
				<img width="100%" src="../assets/bg.png" alt="">
			</div>

			<div class="s-success" style="margin-top: 40px">
				<div class="s-s-title">提交成功！</div>
				<div class="s-s-row">AI图片生成后</div>
				<div class="s-s-row">将由工作人员发送给您</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.sucai-list {
  margin: 20px auto;
  box-sizing: border-box;

  .sucai-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
    border: 2px solid #fff;

    .img {
      margin: 10px;
      flex: 1;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .active {
    border: 2px solid #FFBDB0;
    border-radius: 4px;
  }
}
</style>
  
<script>
export default {
	data() {
		return {
			step: 1,
			tplList: [],
			dengguang: {
				value: '',
				showPicker: false,
				columns: [
					{
						text: '好看的灯光 beautiful lighting',
						value: 'beautiful lighting'
					},
					{
						text: '柔软的光线 soft light',
						value: 'soft light'
					},
					{
						text: '电影光 Cinematic light',
						value: 'Cinematic light'
					},
					{
						text: '体积光 Volumetric light',
						value: 'Volumetric light'
					},
					{
						text: '影棚光 Studio light',
						value: 'Studio light'
					},
					{
						text: '明亮的光线 Bright',
						value: 'Bright'
					},
					{
						text: '戏剧光 Dramatic light',
						value: 'Dramatic light'
					},
					{
						text: '赛博朋克光 Cyberpunk light',
						value: 'Cyberpunk light'
					},
					{
						text: '体积照明 Volumetric lighting',
						value: 'Volumetric lighting'
					},
					{
						text: '情绪照明 Mood lighting',
						value: 'Mood lighting'
					},
					{
						text: '荧光灯 Fluorescent lighting',
						value: 'Fluorescent lighting'
					},
					{
						text: '黄昏射线 Crepuscular Ray',
						value: 'Crepuscular Ray'
					},
					{
						text: '外太空观 Outer space view',
						value: 'Outer space view'
					},
					{
						text: '戏剧灯光 Dramatic lighting',
						value: 'Dramatic lighting'
					},
					{
						text: '双性照明 Bisexual lighting',
						value: 'Bisexual lighting'
					},
					{
						text: '伦勃朗照明 Rembrandt Lighting',
						value: 'Rembrandt Lighting'
					},
					{
						text: '分体照明 Split Lighting',
						value: 'Split Lighting'
					}
				],
			},
			sediao: {
				value: '',
				showPicker: false,
				columns: [
					{
						text: '米色 Beige',
						value: 'Beige'
					},
					{
						text: '奶油色 Cream',
						value: 'Cream'
					},
					{
						text: '天蓝色 Sky blue',
						value: 'Sky blue'
					},
					{
						text: '乡村色调 Rustic tones',
						value: 'Rustic tones'
					},
					{
						text: '大胆明亮 Bold and bright',
						value: 'Bold and bright'
					},
					{
						text: '复古色调 Retro',
						value: 'Retro'
					},
					{
						text: '流行艺术色调 Pop art',
						value: 'Pop art'
					},
					{
						text: '电影色调 Cinematic',
						value: 'Cinematic'
					},
					{
						text: '黑白色调 Noir',
						value: 'Noir'
					},
					{
						text: '奇幻色调 Fantasy',
						value: 'Fantasy'
					},
					{
						text: '哥特式色调 Gothic',
						value: 'Gothic'
					},
					{
						text: '金银色调 Gold and silver tone',
						value: 'Gold and silver tone'
					},
					{
						text: '梦幻色调 Dreamy',
						value: 'Dreamy'
					},
					{
						text: '浪漫色调 Romantic',
						value: 'Romantic'
					},
					{
						text: '戏剧性色调 Dramatic',
						value: 'Dramatic'
					}
				],
			},
			jingdu: {
				value: '',
				showPicker: false,
				columns: [
					{
						text: '超高清画面 Ultra HD Picture',
						value: 'Ultra HD Picture'
					},
					{
						text: '精细细节 Fine details',
						value: 'Fine details'
					},
					{
						text: '逼真的细节 Realistic details',
						value: 'Realistic details'
					},
					{
						text: '锐利的细节 Sharp details',
						value: 'Sharp details'
					},
					{
						text: '高精度 High-precision',
						value: 'High-precision'
					},
					{
						text: '超逼真 Ultra-realistic',
						value: 'Ultra-realistic'
					},
					{
						text: '超锐利 Super sharp',
						value: 'Super sharp'
					},
					{
						text: '清晰的细节 Crisp details',
						value: 'Crisp details'
					},
					{
						text: '水晶清晰 Crystal clear',
						value: 'Crystal clear'
					},
					{
						text: '照片般逼真 Photorealistic',
						value: 'Photorealistic'
					},
					{
						text: '完美的画面 Picture-perfect',
						value: 'Picture-perfect'
					},
					{
						text: '无瑕疵的 Flawless',
						value: 'Flawless'
					},
					{
						text: '精雕细琢 Meticulously crafted',
						value: 'Meticulously crafted'
					}


				],
			},
			yishufengge: {
				value: '',
				showPicker: false,
				columns: [
					{
						text: '文艺复兴 Renaissance',
						value: 'Renaissance'
					},
					{
						text: '野兽派 Fauvism',
						value: 'Fauvism'
					},
					{
						text: '立体派 Cubism',
						value: 'Cubism'
					},
					{
						text: '抽象表现主义 Abstract Art',
						value: 'Abstract Art'
					},
					{
						text: '超现实主义 Surrealism',
						value: 'Surrealism'
					},
					{
						text: '欧普艺术/光效应艺术 OP Art / Optical Art',
						value: 'OP Art / Optical Art'
					},
					{
						text: '维多利亚时代 Victorian',
						value: 'Victorian'
					},
					{
						text: '未来主义 Futuristic',
						value: 'Futuristic'
					},
					{
						text: '极简主义 Minimalist',
						value: 'Minimalist'
					},
					{
						text: '粗扩主义 Brutalist',
						value: 'Brutalist'
					},
					{
						text: '建构主义 Constructivist',
						value: 'Constructivist'
					},
					{
						text: '旷野之息 Breath of the Wild',
						value: 'Breath of the Wild'
					}

				],
			},

			token: '',
			thumbArr: [],

			form: {
				token: null,
				tpl_id: null,
				tpl_imgs: [],
				dengguang: '',
				sediao: '',
				jingdu: '',
				yishufengge: '',
				name: '',
				phone: '',
				keywords: "",
			},
		};
	},

	async created() {
		await this.getSucaiList()
		const token = localStorage.getItem('token')
		if (token) {
			// this.step = 2
			this.token = token
			await this.getDetail()
		}
	},
	mounted() {

	},
	methods: {
		async getSucaiList() {
			const tplList = await this.$axios.post("/getSucaiList");
			if (tplList.data.length > 0) {
				this.tplList = tplList.data
				if (this.tplList.length) {
					this.form.tpl_id = this.tplList[0].id
					this.form.tpl_imgs = this.tplList[0].show_imgs;
				}
			}
			console.log(this.tplList, 299292);
		},

		async getDetail() {
			const formData = await this.$axios.post("/getUserForm");
			console.log(formData, 282882);
			if (formData.id) {
				this.form.name = formData.name
				this.form.phone = formData.phone
			}
		},

		selectTpl(tpl) {
			this.form.tpl_id = tpl.id;
			this.form.tpl_imgs = tpl.show_imgs
		},

		onConfirm1(value) {
			this.form.dengguang = value.value;
			this.dengguang.value = value.text;
			this.dengguang.showPicker = false;
		},

		onConfirm2(value) {
			this.form.sediao = value.value;
			this.sediao.value = value.text;
			this.sediao.showPicker = false;
		},
		onConfirm3(value) {
			this.form.jingdu = value.value;
			this.jingdu.value = value.text;
			this.jingdu.showPicker = false;
		},

		onConfirm4(value) {
			this.form.yishufengge = value.value;
			this.yishufengge.value = value.text;
			this.yishufengge.showPicker = false;
		},

		onSubmit1() {
			if (!this.form.name) return this.$toast.fail('请输入姓名或昵称')
			if (!this.form.phone) return this.$toast.fail('请输入手机号')

			if (this.form.name.length > 10) {
				this.$toast.fail('姓名/昵称不能超过10个字符')
				return
			}
			if (this.form.phone.length != 11) {
				this.$toast.fail('手机号码不正确')
				return
			}
			//都填了的话跳转到第二阶段
			this.step = 2
		},

		onSubmit2() {
			if (!this.form.tpl_id) return this.$toast.fail('请选择一组素材图')
			this.step = 3
		},
		async onSubmit3() {
			if (!this.form.keywords) return this.$toast.fail('请输入关键词')
			if (this.form.name.keywords > 200) {
				this.$toast.fail('关键词不能超过200个字符')
				return
			}
			this.create()
		},

		async create() {
			const addResult = await this.$axios.post("/form/create", this.form);

			if (addResult.status == 1) {
				this.$toast.success(addResult.msg)
				this.step = 4
				if (!this.token) {
					localStorage.setItem('token', addResult.data.token)
				}
			} else {
				this.$toast.fail(addResult.msg)
			}
		}
	},
};
</script>